import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
	name: string;
	label: string;
	editable: boolean;
	edit: boolean;
	isInvalidFormat?: boolean;
	min?: string;
}

export const DateField: FC<Props> = ({ name, label, editable, edit, isInvalidFormat, min }) => {
	const { register } = useFormContext();

	return (
		<div className={`form_row ${!(editable && edit) ? 'read_only' : ''}`}>
			<label htmlFor={name} className="input_label col-3">
				{label}
			</label>
			<div className="col-6">
				<input
					type={'date'}
					id={name}
					className={` normal focus full_w ${isInvalidFormat ? 'red_hot_border' : ''}`}
					name={name}
					disabled={!editable || !edit}
					ref={register}
					min={min}
				/>
			</div>
		</div>
	);
};
