import { axiosInstance } from '../config';
import { AmlParams, AmlDataType } from './../../pages/reports/aml/types';
import { fileNameFromHeaders } from '../../utils/getExportFileName';

export const amlReport = {
	getAmlReport(params: AmlParams): Promise<{ total: number; items: AmlDataType[] }> {
		return axiosInstance.post('/reporting/aml_report', params).then((res) => res.data);
	},

	exportAmlReport(params: AmlParams): Promise<any> {
		return axiosInstance.post('/reporting/aml_report/download', { ...params }).then((res) => {
			return [fileNameFromHeaders(res.headers, 'aml_report'), res.data];
		});
	},
};
