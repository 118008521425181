import React, { FC, KeyboardEvent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import { useInitialMount } from '../../../../../hooks';
import { useNotify } from '../../../../../hooks/useNotifications';
import { useOnlineUser } from '../../../context/OnlineUserContext';
import { OnlineUser } from '../../../types';
import { IDCard, Passport } from '../types';
import { idCard, passport } from '../utils';
import { UserInfo } from './UserInfo';
import { isEmpty } from 'lodash';

interface Props {
	edit: boolean;
	setEdit: (edit: boolean) => void;
	watcher: number;
	setIsDisabled: (isDisabled: boolean) => void;
}

export const FormUserInfo: FC<Props> = ({ edit, setEdit, watcher, setIsDisabled }) => {
	const { t } = useTranslation();
	const { onlineUser, setOnlineUser } = useOnlineUser();
	const [documentType, setDocumentType] = useState<IDCard | Passport>(onlineUser.idCard ? idCard : passport);
	const methods = useForm<OnlineUser>({
		defaultValues: { ...onlineUser },
		shouldUnregister: false,
	});

	const initialMount = useInitialMount();

	const { success, warn } = useNotify();

	const [isInvalidID, setIsInvalidID] = useState<boolean>(false);

	useEffect(() => {
		if (initialMount) return;
		methods.reset(onlineUser);
	}, [watcher]);

	function onKeyDown(event: KeyboardEvent<HTMLFormElement>) {
		//@ts-ignore
		if ((event.charCode || event.keyCode) === 13 && event?.target?.type !== 'textarea') {
			event.preventDefault();
		}
	}

	const submitHandler = async (data: OnlineUser) => {
		let userPoliticallyData = false;
		try {
			setIsDisabled(true);
			const user: OnlineUser = documentType === idCard ? { ...data, passport: null } : { ...data, idCard: null };
			const {
				ticketInSum,
				ticketOutSum,
				depositInSum,
				depositOutSum,
				gamingInSum,
				gamingOutSum,
				numberOfTickets,
				numberOfWinningTickets,
				reservedAmount,
				internetBalance,
				freeBetBalance,
				averageEventNumber,
				averageMaxQuota,
				hasVerificationData,
				...userPayload
			} = user;

			const hasPassport = user.passport ? true : false;
			const hasIdCard = user.idCard ? true : false;

			const userIdFieldsAreEmpty = !hasIdCard && !hasPassport && user.nationalId === '';
			const hasPassportNationalId = hasPassport && user.nationalId === '';

			let birthDate = user.birthDate.split('-');
			let birthDateFormated = birthDate[2] + birthDate[1] + birthDate[0].substring(1, 4);

			if (hasPassportNationalId || userIdFieldsAreEmpty) {
				setIsInvalidID(false);
			} else if (
				!Number(user.nationalId) ||
				user.nationalId.length !== 13 ||
				user.nationalId.substring(0, 7) !== birthDateFormated
			) {
				setIsInvalidID(true);
				warn(t('toast.warn.updateProblem', { entity: t('usersAdministrations.nationalIdOrBirthDate') }));
				return;
			}

			setIsInvalidID(false);
			try {
				const isChangedPoliticianFlag = Boolean(data.isNotPolitician) !== Boolean(onlineUser.isNotPolitician);
				const isChangedPoliticianUntil =
					(Boolean(data.dtPoliticallyActiveUntil) ||
						Boolean(data.dtPoliticallyActiveUntil) !== Boolean(onlineUser.dtPoliticallyActiveUntil)) &&
					data.dtPoliticallyActiveUntil !== onlineUser.dtPoliticallyActiveUntil;

				if (isChangedPoliticianFlag || isChangedPoliticianUntil) {
					const updated = await api.users.updateUserPoliticallyData(onlineUser.uid, {
						isNotPolitician: data.isNotPolitician,
						dtPoliticallyActiveUntil: isEmpty(data.dtPoliticallyActiveUntil)
							? null
							: data.dtPoliticallyActiveUntil,
					});
					setOnlineUser(updated);
					success(t('toast.success.updateSuccessful', { entity: t('usersAdministrations.politicianData') }));
					userPoliticallyData = true;
					setEdit(false);
				}
			} catch (err: any) {
				const errorText = err?.response?.data.detail?.[0]?.msg || err?.response?.data?.detail;
				if (errorText !== '' && errorText !== undefined) {
					warn(errorText);
				} else warn(t('toast.warn.updateProblem', { entity: t('usersAdministrations.politicianData') }));
			}
			const userFieldsForbiddenUpdate = ['selfBanned', 'isNotPolitician', 'dtPoliticallyActiveUntil'];

			const apiPayload = Object.entries(userPayload)
				.map(([key, value]) => [key, value === '' ? null : value])
				.filter(([key, value]) => value !== undefined && !userFieldsForbiddenUpdate.includes(key?.toString() ?? ''))
				.reduce((prev, [key, value]) => {
					return {
						...prev,
						[key as string]: value,
					};
				}, {});

			const updated = await api.users.updateUser(onlineUser.uid, apiPayload);

			setOnlineUser(updated);
			setEdit(false);
			success(t('toast.success.updateSuccessful', { entity: t('usersAdministrations.onlineUser') }));
		} catch (err: any) {
			if (userPoliticallyData) return;

			const errorText = err?.response?.data.detail?.[0]?.msg || err?.response?.data?.detail;
			if (errorText !== '' && errorText !== undefined) {
				warn(errorText);
			} else warn(t('toast.warn.updateProblem', { entity: t('usersAdministrations.onlineUser') }));
		} finally {
			setIsDisabled(false);
		}

		userPoliticallyData = false;
	};

	return (
		<FormProvider {...methods}>
			<form id="hook-form" onSubmit={methods.handleSubmit(submitHandler)} onKeyDown={onKeyDown}>
				<UserInfo
					edit={edit}
					documentType={documentType}
					setDocumentType={setDocumentType}
					isInvalidID={isInvalidID}
				/>
			</form>
		</FormProvider>
	);
};
