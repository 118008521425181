import React, { FC, useState, useEffect, useContext, createContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'use-debounce/lib';
import api from '../../../../api';
import { useAxios } from '../../../../hooks/useAxios';
import { useNotify } from '../../../../hooks/useNotifications';
import { GeoContext as GeoContextType, Node, HorizontalTab, VerticalTab, FieldMap, GeoParameter } from '../types';
import { formatPercentageValues } from '../utils';
import getErrorText from '../../../../utils/getErrorMsg';

const GeoContext = createContext({} as GeoContextType);
interface ProviderProps {
	user?: string;
}

const GeoProvider: FC<ProviderProps> = (props) => {
	const { t } = useTranslation();
	const [selectedNode, selectNode] = useState<Node | null>(null);

	const [selectedDevice, selectDevice] = useState<HorizontalTab | null>(null);

	const [verticalTabs] = useState<VerticalTab[]>([]);
	const [selectedGroup, selectGroup] = useState<VerticalTab | null>(null);

	const [selectedInstance, setSelectedInstance] = useState<'LANDBASE' | 'ONLINE'>('LANDBASE');

	const [searchValue, setSearch] = useState('');
	const [treeSearch, setTreeSearch] = useState('');
	const [treeLoader, setTreeLoader] = useState(false);

	const [refreshed, setRefreshed] = useState(0);
	const refresh = () => setRefreshed(Date.now());

	const [debouncedText] = useDebounce(searchValue, 500);

	const { warn, success, error, notify } = useNotify();

	const [isLoading, setIsLoading] = useState(false);

	const { data: horizontalTabs } = useAxios<HorizontalTab[]>({
		apiFunction: async function (): Promise<HorizontalTab[]> {
			try {
				return await api.geo.getTabs();
			} catch (err) {
				error(getErrorText(err) || 'toast.error.defaultText');
			}

			return horizontalTabs;
		},
		initialData: [],
		dependencies: [],
	});

	const { data: fieldMap, setState: updateFieldMap } = useAxios<FieldMap>({
		apiFunction: async function (): Promise<FieldMap> {
			try {
				return await api.geo.getFieldMap();
			} catch (err) {
				error(getErrorText(err) || 'toast.error.defaultText');
			}

			return fieldMap;
		},
		initialData: {},
		dependencies: [],
	});

	const [horizontalTabsFiltered, setHorizontalTabsFiltered] = useState(horizontalTabs);

	useEffect(() => {
		if (!Object.keys(fieldMap) || debouncedText.length < 3) return setHorizontalTabsFiltered(horizontalTabs);

		setHorizontalTabsFiltered(
			horizontalTabs
				.map((tab) => {
					if (tab.key.toLowerCase().includes(debouncedText.toLowerCase())) return tab;
					else
						return {
							...tab,
							items: tab.items
								.map(({ key, items }) => {
									if (key.toLowerCase().includes(debouncedText.toLowerCase())) return { key, items };
									else {
										const filteredItems = [] as string[];
										items.forEach(
											(item) =>
												fieldMap[item].label.toLowerCase().includes(debouncedText.toLowerCase()) &&
												filteredItems.push(item)
										);

										return { key, items: filteredItems };
									}
								})
								.filter((item) => item.items.length),
						};
				})
				.filter((item) => item.items.length)
		);
	}, [horizontalTabs, debouncedText]);

	const [initialGeoParameters, setInitialGeoParameters] = useState({} as GeoParameter);
	const [geoParameters, setGeoParameters] = useState({} as GeoParameter);
	const [modifiedParams, setModifiedParams] = useState({} as { [key: string]: any });

	useEffect(() => {
		if (props.user) {
			if ((!selectedDevice && !selectedGroup) || !Object.values(fieldMap).length)
				return setGeoParameters({} as GeoParameter);
			(async function getUserParameters() {
				const data = await api.geo.getEntityParametersForUser(props.user!, selectedDevice!.key, selectedGroup!.key);
				setParams(data);
			})();
		} else {
			if (!selectedNode || (!selectedDevice && !selectedGroup)) return setGeoParameters({} as GeoParameter);

			(async function getParameters() {
				let data;
				try {
					setIsLoading(true);
					data =
						selectedInstance === 'LANDBASE'
							? await api.geo.getEntityParameters(selectedNode.id, selectedDevice!.key, selectedGroup!.key)
							: await api.geo.getEntityParametersForOnline(
									selectedNode.id,
									selectedDevice!.key,
									selectedGroup!.key
							  );
				} catch (err) {
					error(getErrorText(err) || 'toast.error.defaultText');
				} finally {
					setIsLoading(false);
				}

				setParams(data);
			})();
		}
	}, [selectedNode, selectedGroup, refreshed, selectedInstance, fieldMap]);

	const setParams = (data: any) => {
		setInitialGeoParameters({
			...data,
			entity_params: formatPercentageValues(data.entity_params, fieldMap),
			inherited_params: formatPercentageValues(data.inherited_params, fieldMap),
		});
		setGeoParameters({
			...data,
			entity_params: formatPercentageValues(data.entity_params, fieldMap),
			inherited_params: formatPercentageValues(data.inherited_params, fieldMap),
		});
	};

	const updateGeoParameters = (id: string, value: any) => {
		setGeoParameters((geoParameters) => ({
				...geoParameters,
				entity_params: {
					...geoParameters.entity_params,
					[id]: value,
				},
		}));

		setModifiedParams((params: { [key: string]: any }) => {
			const unchanged =
				initialGeoParameters.entity_params[id] === value ||
				(initialGeoParameters.entity_params[id] === null && value === null);
			if (unchanged) {
				delete params[id];
				return { ...params };
			}
			return { ...params, [id]: value };
		});
	};

	const updateDescriptionGeoParameters = (id: string, value: any) => {
		setModifiedParams((params: { [key: string]: any }) => {
			const unchanged =
				initialGeoParameters.entity_params[id] === value ||
				(initialGeoParameters.entity_params[id] === null && value === null);
			if (unchanged) {
				delete params[id];
				return { ...params };
			}
			const initialValue = initialGeoParameters.entity_params[id];
			return { ...params , [id]: { ...initialValue, ...value } };
		});
	};

	const acceptChanges = async () => {
		const newParams = {};

		for (const key in modifiedParams) {
			const { data_type } = fieldMap[key].field_type_specifics;
			if (modifiedParams[key] === undefined || modifiedParams[key] === '') {
				newParams[key] = null;
			} else if (data_type === 'percentage' && modifiedParams[key] !== null) {
				newParams[key] = modifiedParams[key] / 100;
			} else if (data_type === 'multi_select' && !modifiedParams[key]?.length) {
				newParams[key] = null;
			} else {
				newParams[key] = modifiedParams[key];
			}
		}

		try {
			const data = props.user
				? await api.geo.updateUserGeoParameters(props.user, {
						group: selectedDevice!.key,
						subgroup: selectedGroup!.key,
						parameters: newParams,
				  })
				: await api.geo.updateGeoParameters(geoParameters!.id, {
						group: selectedDevice!.key,
						subgroup: selectedGroup!.key,
						parameters: newParams,
				  });

			setParams(data);
			setModifiedParams({} as GeoParameter);

			success('geo.parametersSaved', {
				position: 'top-right',
				autoClose: 1400,
				style: { marginTop: '75px', backgroundColor: '#7fbd22' },
			});
		} catch (err: any) {
			const errorMsg = err?.response?.data?.detail || t('geo.genericError');
			let errorPreview = errorMsg.split('\n').filter((msg: string) => !!msg);

			const errorsWithLabel = errorPreview
				.map((msg: string) => {
					const [id, value = null] = msg.split(':');
					if (value === null) return id;
					else return `${fieldMap?.[id]?.label || `${id}`}:${value}`;
				})
				.join('\n');

			notify(fieldMap?.[`${errorPreview?.[0].split(':')[0]}`] ? errorsWithLabel : errorMsg, 'error', {
				position: 'top-right',
				hideProgressBar: true,
				closeOnClick: true,
				autoClose: 2000,
				style: { backgroundColor: '#e35454', whiteSpace: 'pre-wrap', marginTop: '75px' },
			});
		}
	};

	const copyLandbaseToOnline = async () => {
		try {
			const data = await api.geo.copyParametersFromLandbaseToOnline({
				group: selectedDevice!.key,
				subgroup: selectedGroup!.key,
				to_entity: geoParameters.id,
			});

			setParams(data);

			success('geo.parametersCopied', {
				position: 'top-right',
				autoClose: 1400,
				style: { marginTop: '75px', backgroundColor: '#7fbd22' },
			});
		} catch (err: any) {
			const errorMsg = err?.response?.data?.detail || t('geo.genericError');
			notify(errorMsg, 'error', {
				position: 'top-right',
				hideProgressBar: true,
				closeOnClick: true,
				autoClose: 2000,
				style: { backgroundColor: '#e35454', whiteSpace: 'pre-wrap', marginTop: '75px' },
			});
		}
	};

	const updateDescription = async (parameter_key: string, description: string) => {
		try {
			const field = await api.geo.updateDescription({ parameter_key, description });
			updateFieldMap({ ...fieldMap, [parameter_key]: field });

			success('geo.descriptionSaved', {
				position: 'top-right',
				autoClose: 1000,
				style: { marginTop: '75px', backgroundColor: '#7fbd22' },
			});
		} catch (err) {
			console.error(err);
		}
	};

	const dropChanges = () => {
		setGeoParameters(initialGeoParameters);
		setModifiedParams({} as GeoParameter);
	};

	const saveWarning = () => {
		warn('general.saveChanges', {
			position: 'top-right',
			autoClose: 1300,
			style: { marginTop: '75px', backgroundColor: '#fd9800' },
		});
	};

	return (
		<GeoContext.Provider
			value={{
				user: props.user,
				refresh,
				fieldMap,
				verticalTabs,
				selectNode,
				selectedNode,
				selectGroup,
				selectedGroup,
				treeSearch,
				setTreeSearch,
				setSearch,
				selectDevice,
				selectedDevice,
				treeLoader,
				setTreeLoader,
				geoParameters,
				saveWarning,
				acceptChanges,
				dropChanges,
				modifiedParams,
				setModifiedParams,
				updateGeoParameters,
				updateDescriptionGeoParameters,
				initialGeoParameters,
				updateDescription,
				selectedInstance,
				setSelectedInstance,
				searchValue: debouncedText,
				copyLandbaseToOnline,
				horizontalTabs: horizontalTabsFiltered,
				modified: !!Object.keys(modifiedParams).length,
				isLoading,
			}}
			{...props}
		/>
	);
};

const useGeo = () => useContext(GeoContext);

export { GeoProvider, useGeo };
