import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDCard, Passport } from '../types';
import { DateField } from './DateField';
import { DocumentSelect } from './DocumentSelect';
import { RadioGroup } from './RadioGroup';
import { TextField } from './TextField';
import { TimeField } from './TimeField';
import { genderOptions } from '../utils';
import { ChangeUserBaseLocation } from './ChangeUserBaseLocation';
import { ChangeUserStatus } from './ChangeUserStatus';
import { SwitchField } from './SwitchField';
import { DescriptionField } from './DescriptionField';
import { DateTimeField } from './DateTimeField';
import { TextFieldWithButton } from './TextFieldWithButton';
import { EditEmailModal } from './EditEmailModal';
import { useUser } from '../../../../../context/UserProvider';
import { useFormContext } from 'react-hook-form';
import api from '../../../../../api';
import NotificationModal from '../../../../../components/general/NotificationModal';
import Delete from '../../../../../utils/svg/Delete';
import { useNotify } from '../../../../../hooks/useNotifications';
import { useOnlineUser } from '../../../context/OnlineUserContext';
import { DateValueFiled } from './DateValueFiled';

interface Props {
	edit: boolean;
	documentType: IDCard | Passport;
	setDocumentType: (type: IDCard | Passport) => void;
	isInvalidID?: boolean;
}

export const SelfBannedTo: FC<{ label: string; displaySymbol: boolean }> = ({ label, displaySymbol }) => {
	if (displaySymbol) {
		return (
			<div className="form_row read_only">
				<label className="input_label col-3 ">{label}</label>
				<div className="col-6">
					<p style={{ fontSize: '22px', padding: '0 8px' }}>&#8734;</p>
				</div>
			</div>
		);
	}

	return <DateValueFiled name={'dtSelfBannedTo'} label={label} displayTime={false} />;
};

export const UserInfo: FC<Props> = ({ edit, documentType, setDocumentType, isInvalidID }) => {
	const [showSelfBanModal, setShowSelfBanModal] = useState(false);
	const [updateSelfBanLoader, setUpdateSelfBanLoader] = useState(false);

	const { success, warn } = useNotify();
	const { setOnlineUser } = useOnlineUser();

	const { t } = useTranslation();

	const { getValues, setValue, watch } = useFormContext();

	const selfBanned = getValues('selfBanned');
	const selfBannedTo = getValues('dtSelfBannedTo');
	const userUid = getValues('uid');

	const { isNotPolitician } = watch();

	useEffect(() => {
		if (isNotPolitician) setValue('dtPoliticallyActiveUntil', null);
	}, [isNotPolitician, setValue]);

	const openSelfBanModal = () => {
		setShowSelfBanModal(true);
	};

	const updateSelfBannedUserData = async () => {
		setUpdateSelfBanLoader(true);

		try {
			const resUserData = await api.users.updateSelfBanned(userUid, selfBanned);

			setOnlineUser(resUserData);

			// Reset date values
			setValue('dtSelfBannedFrom', null);
			setValue('dtSelfBannedTo', null);

			success(t('toast.success.updateSuccessful', { entity: t('usersAdministrations.selfBanned') }));
		} catch (error) {
			setValue('selfBanned', !selfBanned);
			warn(t('toast.warn.updateProblem', { entity: t('usersAdministrations.selfBanned') }));
		}

		setUpdateSelfBanLoader(false);
		setShowSelfBanModal(false);
	};

	const closeBannedUserModal = () => {
		setValue('selfBanned', !selfBanned);
		setShowSelfBanModal(false);
	};

	const [editMailModal, setEditEmailModal] = useState(false);

	const [newEmail, setNewEmail] = useState('');

	const closeEditEmailModal = () => {
		setNewEmail('');
		setEditEmailModal(false);
	};
	const openEditEmailModal = () => {
		setEditEmailModal(true);
	};
	const { roles } = useUser();
	const [emailChangeRole] = useState(roles && roles['support'].includes('USER_EMAIL_CHANGE'));

	const [politicianChangeRole] = useState(roles && roles['support'].includes('USER_POLITICALLY_ACTIVE_UPDATE'));

	return (
		<>
			<div className="flex_direction_row padding_30">
				<div className="col-4 padding_30 margin_left_auto margin_right_auto">
					<TextField
						name={'username'}
						label={`${t('usersAdministrations.userName')}`}
						editable={false}
						edit={edit}
					/>
					<TextFieldWithButton
						name={'email'}
						label={`${t('usersAdministrations.email')}`}
						editable={false}
						edit={edit}
						openEditEmailModal={openEditEmailModal}
						showBtn={emailChangeRole}
						newEmail={newEmail}
					/>
					<TextField name={'code'} label={`${t('usersAdministrations.code')}`} editable={false} edit={edit} />
					<TextField name={'id'} label={`${t('usersAdministrations.userId')}`} editable={false} edit={edit} />
					<TextField name={'firstName'} label={`${t('usersAdministrations.name')}`} editable={true} edit={edit} />
					<TextField
						name={'lastName'}
						label={`${t('usersAdministrations.lastName')}`}
						editable={true}
						edit={edit}
					/>
					<TextField
						name={'nickname'}
						label={`${t('usersAdministrations.nickname')}`}
						editable={true}
						edit={edit}
					/>
					{/* <TextField name={'nickname'} label={`${t('usersAdministrations.nickname')}`} editable={true} edit={edit} /> */}

					<ChangeUserStatus edit={edit} />
					<ChangeUserBaseLocation edit={edit} />

					<TimeField name={'created'} label={`${t('usersAdministrations.registrationDate')}`} />
					<TimeField name={'dtVerified'} label={`${t('usersAdministrations.activationDate')}`} />
					<TextField
						name={'registrationIp'}
						label={`${t('usersAdministrations.registrationIp')}`}
						editable={false}
						edit={edit}
					/>
					<TextField
						name={'lastIpAddress'}
						label={`${t('usersAdministrations.lastIp')}`}
						editable={false}
						edit={edit}
					/>
					<TimeField name={'lastActive'} label={`${t('usersAdministrations.lastActive')}`} />

					<div className="col_border_bottom col_border_top">
						<TextField
							name={'accountVerifiedBy'}
							label={`${t('usersAdministrations.verificationOperator')}`}
							editable={false}
							edit={edit}
						/>
						<TimeField name={'dtAccountVerified'} label={`${t('usersAdministrations.verificationTime_Date')}`} />
						<SwitchField
							name={'hasVerificationData'}
							label={`${t('usersAdministrations.hasVerificationData')}`}
							onLabel={`${t('general.yes')}`}
							offLabel={`${t('general.no')}`}
							editable={edit}
							edit={edit}
						/>
					</div>
				</div>

				<div className="col-4 padding_30 margin_left_auto margin_right_auto">
					<TextField
						name={'nationalId'}
						label={`${t('usersAdministrations.nationalId')}`}
						editable={true}
						edit={edit}
						isInvalidID={isInvalidID}
					/>

					<DocumentSelect edit={edit} documentType={documentType} setDocumentType={setDocumentType} />

					<DateField
						name={'birthDate'}
						label={`${t('usersAdministrations.birthDate')}`}
						editable={true}
						edit={edit}
						isInvalidFormat={isInvalidID}
					/>

					<RadioGroup
						name={'gender'}
						label={`${t('usersAdministrations.gender')}`}
						editable={true}
						edit={edit}
						options={genderOptions}
					/>
					<TextField
						name={'telephone'}
						label={`${t('usersAdministrations.phoneNumber')}`}
						editable={true}
						edit={edit}
					/>
					<TextField name={'address'} label={`${t('usersAdministrations.address')}`} editable={true} edit={edit} />

					<TextField name={'city'} label={`${t('usersAdministrations.city')}`} editable={true} edit={edit} />

					<TextField name={'zip'} label={`${t('usersAdministrations.zip')}`} editable={true} edit={edit} />

					<TextField name={'country'} label={`${t('usersAdministrations.country')}`} editable={true} edit={edit} />
					<TextField
						name={'operator'}
						label={`${t('usersAdministrations.operator')}`}
						editable={false}
						edit={edit}
					/>
					<TextField name={'uid'} label="UUID" editable={false} edit={edit} />
					<TextField
						name="bankAccount"
						label={`${t('usersAdministrations.bankAccount')}`}
						editable={true}
						edit={edit}
					/>
				</div>
				<div className="col-4 padding_30 margin_left_auto margin_right_auto">
					<SwitchField
						name={'smsPromotions'}
						label={`${t('usersAdministrations.smsPromo')}`}
						onLabel={`${t('general.active')}`}
						offLabel={`${t('general.disabled')}`}
						editable={edit}
						edit={edit}
					/>
					<SwitchField
						name={'emailPromotions'}
						label={`${t('usersAdministrations.emailPromo')}`}
						onLabel={`${t('general.active')}`}
						offLabel={`${t('general.disabled')}`}
						editable={edit}
						edit={edit}
					/>
					<SwitchField
						name={'pushPromotions'}
						label={`${t('usersAdministrations.pushPromo')}`}
						onLabel={`${t('general.active')}`}
						offLabel={`${t('general.disabled')}`}
						editable={edit}
						edit={edit}
					/>
					<SwitchField
						name={'sitePromotions'}
						label={`${t('usersAdministrations.sitePromo')}`}
						onLabel={`${t('general.active')}`}
						offLabel={`${t('general.disabled')}`}
						editable={edit}
						edit={edit}
					/>
					<SwitchField
						name={'callPromotions'}
						label={`${t('usersAdministrations.callPromo')}`}
						onLabel={`${t('general.active')}`}
						offLabel={`${t('general.disabled')}`}
						editable={edit}
						edit={edit}
					/>
					<div className="col_border_bottom col_border_top">
						<SwitchField
							name={'isNotPolitician'}
							label={`${t('usersAdministrations.notPolitician')}`}
							onLabel={`${t('general.active')}`}
							offLabel={`${t('general.disabled')}`}
							editable={edit && politicianChangeRole}
							edit={edit}
						/>
						<DateField
							name={'dtPoliticallyActiveUntil'}
							label={`${t('usersAdministrations.politicallyExpirationDate')}`}
							editable={edit && !isNotPolitician && politicianChangeRole}
							edit={edit}
							isInvalidFormat={isInvalidID}
						/>
					</div>
					<div className="col_border_bottom col_border_top">
						<SwitchField
							name={'selfBanned'}
							label={`${t('usersAdministrations.selfBanned')}`}
							onLabel={`${t('general.active')}`}
							offLabel={`${t('general.disabled')}`}
							editable={selfBanned}
							edit={true}
							onChange={openSelfBanModal}
						/>
						<DateValueFiled
							name={'dtSelfBannedFrom'}
							label={`${t('usersAdministrations.selfBannedFrom')}`}
							displayTime={true}
						/>
						<SelfBannedTo
							label={`${t('usersAdministrations.selfBannedTo')}`}
							displaySymbol={selfBanned && !selfBannedTo}
						/>
					</div>

					{/* selfBanned notification modal*/}
					<NotificationModal
						opened={showSelfBanModal}
						cancelText={t('general.cancel')}
						confirmText={t('general.confirm')}
						cancelAction={closeBannedUserModal}
						confirmAction={updateSelfBannedUserData}
						loading={updateSelfBanLoader}
					>
						<div className="context_icon margin_top_40">
							<Delete />
						</div>

						<h2 data-testid="modal-msg" className="modal_message_title">
							{t('Are you sure ?')}
						</h2>
					</NotificationModal>

					<DescriptionField
						name={'comment'}
						label={`${t('usersAdministrations.comment')}`}
						editable={true}
						edit={edit}
					/>
				</div>
			</div>
			{editMailModal ? (
				<EditEmailModal close={closeEditEmailModal} setNewEmail={setNewEmail} newEmail={newEmail} />
			) : null}
		</>
	);
};
